@import "../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: 'Poetsen One';
  src: url('/src/assets/poetsen-one-latin-400-normal.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('/src/assets/roboto-latin-100-normal.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'Roboto';
  src: url('/src/assets/roboto-latin-300-normal.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url('/src/assets/roboto-latin-400-normal.ttf');
  font-weight: 400;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: $font-size-base;
}

a {
  text-decoration: none;
  color: #ff9118;
}
@import "/src/style";

main {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 100px;
}

@media screen and (max-width: 470px) {
  .title {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
}

.text {
  flex: 1;
}

.profile {
  max-width: 180px;
  height: auto;
  border-radius: 100%;
}

h1 {
  font-family: 'Poetsen One', system-ui;
  font-size: 45px;
}

.like {
  display: block;
  max-width: 520px;
}

section {
  position: relative;
  border-top: 1px solid black;
  padding-top: 12px;
  padding-bottom: 70px;

  h2 {
    position: absolute;
    background: white;
    top: 0;
    left: 16px;
    transform: translateY(-65%);
    font-size: $font-size-base;
    font-family: 'Poetsen One', system-ui;
    padding: 0 4px;
  }

  span {
    color: $secondary;
  }

  h3 {
    @extend .pt-3;
    font-size: $font-size-base;
    margin: 0;
  }

  small {
    display: block;
    color: $secondary;
    font-size: $font-size-sm;
  }
}

.gallery {
  width: 100%;
  margin-top: 20px;

  display: flex;
  justify-content: center;
  gap: 10px;

  img {
    display: block;
    flex-basis: 0;
    object-fit: cover;
    width: 0;
    height: 100%;
    cursor: pointer;
    border: 1px solid $secondary;
    border-radius: 10px;
  }

  @media screen and (max-width: 575px) {
    & {
      flex-wrap: wrap;

      img {
        width: calc(50% - 10px);
        height: auto;
        flex-grow: initial !important;
      }
    }
  }

  @media screen and (max-width: 340px) {
    & img {
      width: 100%;
    }
  }
}

footer {
  font-size: $font-size-sm;
  color: $secondary;
}

.icon {
  height: 16px;
}

#img-view {
  display: none;
  position: fixed;

  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;

  background: #dddddda0;
  backdrop-filter: blur( 3.5px );
  -webkit-backdrop-filter: blur( 3.5px );

  img {
    max-width: 80%;
    max-height: 80%;
    border: 1px solid $secondary;
    border-radius: 10px;
  }

  &.show {
    display: flex;
  }
}